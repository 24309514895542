img {
  max-width: 200px;
  max-height: 200px;
}

button {
  border: none;
  background: transparent;
  border-radius: 5px;
}

button:hover {
  background: rgba(128, 128, 128, 0.1);
}

.highlighted {
  color: yellow;
  background-color: black;
  font-weight: bold;
  padding: 1px 2px;
}

.yellow-circle {
  position: relative;
  background-color: yellow;
  padding: 3px 5px;
  border: 1px dotted #000;
  border-radius: 25px;
}

.yellow-circle::before {
  content: '';
  white-space: nowrap;
  font-weight: bold;
  font-size: 2px;
  position: absolute;
  top: -10px;
  left: 4px;
}

.image-data__modal {
  position: fixed;
  z-index: 3000;
  top: 50%;
  left: 50%;
  overflow-y: scroll;
  background: #fff;
  border: 10px solid rgba(91, 129, 180, 1);
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.image-data__modal_singular {
  margin-top: -200px;
  margin-left: -100px;
  width: 300px;
  min-height: 400px;
  align-items: center;
}

.image-data__modal_multiple {
  margin-top: -200px;
  margin-left: -300px;
  width: 600px;
  min-height: 400px;
}

.image-data__section-wrapper {
  display: flex;
  gap: 5%;
  overflowX: scroll;
  width: 100%;
}

.image-data__section {
  display: flex;
  flex-direction: column;
}

.image-data__section_singular {
  width: 100%;
  align-items: center;
}

.image-data__section_multiple {
  width: 30%;
  gap: 20px;
}

.section__form {
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: space-around;
  margin: 20px;
  width: 70%;
}

.modal__button-group {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 20px;
}

.table-controls {
  display: flex;
  gap: 40px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.table-controls__section {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.table-controls__section button {
  text-align: left;
  font-family: 'Roboto';
}

.ProseMirror {
  padding: 0px 12px 10px;
  background: rgb(248, 248, 252);
  margin-top: 20px;
  margin-bottom: 10px;
  border-bottom: 1px solid rgb(196, 196, 199);
  max-height: 450px;
  overflow: scroll;
  outline: none;
}

.ProseMirror:focus {
  border-bottom: 2px solid rgba(91, 129, 180, 1);
}

.editor-label {
  font-size: 11px;
  position: relative;
  color: rgba(0, 0, 0, 0.54);
  background: rgb(248, 248, 252);
  width: calc(100% - 24px);
  z-index: 1000;
  margin-top: 0;
  top: 22px;
  padding: 8px 12px;
}

.ProseMirror table {
  width: 50%;
  border-spacing: unset;
}

.ProseMirror th,
.ProseMirror td {
  border: 0.5px solid rgb(195, 195, 195);
  background: #fff;
  text-transform: uppercase;
  font-weight: bold;
}

.ProseMirror p {
  line-height: 24px;
}

.button-menu {
  display: flex;
  align-items: center;
  margin-top: 24px;
}

.button-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 125px;
  gap: 4px;
  font-size: 14px;
}

.font-size__popup {
  position: relative;
}

.font-size__options {
  position: absolute;
  bottom: -130px;
  left: -6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  height: 120px;
  width: 120px;
  z-index: 3000;
  background: #fff;
  padding: 4px;
}

.heading-1 {
  font-weight: bold;
  font-size: 18px;
}

.heading-2 {
  font-weight: bold;
  font-size: 16px;
}

.heading-3 {
  font-weight: bold;
  font-size: 14px;
}

.is-active {
  border: 1px dotted rgba(91, 129, 180, 1);
}

.gray {
  color: gray;
}

.small-letters {
  font-size: 8px;
}