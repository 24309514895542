@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.activity-icon {
  cursor: pointer;
}

.sync-icon {
  animation: spin 1s;
  animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
}

.modal-window {
  position: fixed;
  overflow-y: scroll;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #fff;
  z-index: 5000;
  padding-top: 5%;
  padding-left: 20%;
  padding-right: 20%;
}

.modal-close {
  border-radius: 10%;
  position: fixed;
  right: 3%;
  top: 10%;
  z-index: 200;
  background-color: transparent;
  border: none;
  color: red;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.modal-close__description {
  font-size: 0.9rem;
  text-transform: uppercase;
}

.images-container {
  display: flex;
  flex-direction: column;
}

.draggable-field {
  display: flex;
  z-index: 3000;
  align-items: center;
  flex-direction: column;
  width: 100%;
  list-style-type: none;
  background-color: #fff;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.draggable-image-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.draggable-image-content-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  min-width: 255px;
  margin-right: 5px;
}

.draggable-field-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;
}

.draggable-image {
  pointer-events: none;
  max-width: 13rem;
}

.arrows-container {
  display: flex;
}

.remove-button {
  margin-right: 4rem;
  background-color: transparent;
  border: none;
  color: red;
}

.remove-button {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.node-rubrics-filter {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  font-size: 0.875rem;
  font-family: 'Roboto', 'Helvetica', 'Arial', 'sans-serif';
  margin-bottom: 1.2rem;
}

.node-rubrics-filter__checkbox {
  cursor: pointer;
}

.delete-logo-button {
  color: red !important;
  width: max-content !important;
  padding: 6px 10px !important;
}